import { LanguageType, Product } from '@eo-storefronts/eo-core'
import { Box, Grid } from '@mui/material'
import CartItemActions from '~/src/components/cart/CartItemActions'
import CartItemModifierGroups from '~/src/components/cart/CartItemModifierGroups'
import CartItemName from '~/src/components/cart/CartItemName'
import Price from '~/src/components/Price'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { CartProduct } from '~/src/types/CartProduct'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { PRODUCT_BY_ID_SELECTOR } from '~/src/stores/product'

interface Props {
  cartProduct: CartProduct,
}

const CartItem = ({ cartProduct }: Props) => {
  const muiTheme = useCustomMuiTheme()
  const product = useEoValue<Product | null>(PRODUCT_BY_ID_SELECTOR(cartProduct.id))
  const lang = useEoValue<LanguageType|string>(LANGUAGE_STATE)

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 2,
        gridTemplateColumns: '1fr 3fr 1fr',
        gridTemplateRows: 'repeat(3, auto)',
        gridTemplateAreas: '"image name price" "image ingredients ingredients" "image actions ."',
        width: '100%'
      }}
    >
      <Box
        sx={{
          gridArea: 'image',
          img: {
            width: '100%'
          }
        }}
      >
        <img
          src={ product?.images[0]?.detail }
          alt={ product?.name[lang] || '' }
        />
      </Box>

      <Box sx={{ gridArea: 'name' }}>
        <CartItemName cartProduct={cartProduct} />
      </Box>

      <Box sx={{
        gridArea: 'price',
        textAlign: 'right',
        fontSize: '1.5em',
        [muiTheme.breakpoints.only('xs')]: {
          fontSize: '1.3em'
        }
      }}>
        <Price price={ product?.price || 0 } />
      </Box>

      <Box sx={{ gridArea: 'ingredients' }}>
        <CartItemModifierGroups cartProduct={cartProduct} />
      </Box>

      <Grid />

      <Box sx={{ display: 'flex', gridArea: 'actions', alignItems: 'end' }}>
        <CartItemActions cartProduct={cartProduct} />
      </Box>
    </Box>
  )
}

export default CartItem
