import { Box } from '@mui/material'
import CartContainer from '~/src/components/cart/CartContainer'
import useGetCartTemplate from '~/src/pages/cart/styles/useGetCartTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const Cart = () => {
  const pageName: keyof typeof RoutesEnum = 'CART'
  const styles = useGetCartTemplate()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>

      <Box sx={styles?.getPageSx()}>
        <Box sx={styles?.getInnerBoxSx()}>
          <CartContainer/>
        </Box>
      </Box>
    </SlideFromRight>
  )
}

export default Cart
