import DeleteIcon from '@mui/icons-material/Delete'
import PrimaryButton from '~/src/components/mui-wrappers/buttons/PrimaryButton'
import QuantitySelector from '~/src/components/products/QuantitySelector'
import { useRemoveProductOfCart } from '~/src/hooks/cart/useRemoveProductOfCart'
import { useUpdateCartProducts } from '~/src/hooks/cart/useUpdateCartProducts'
import { useEoValue } from '~/src/hooks/useEoState'
import { CartProduct } from '~/src/types/CartProduct'
import { convertProductUnitKgInGr } from '~/src/services/ProductService'
import { PRODUCT_BY_ID_SELECTOR } from '~/src/stores/product'

interface Props {
  cartProduct: CartProduct,
}

const CartItemActions = ({ cartProduct }: Props) => {
  const product = useEoValue(PRODUCT_BY_ID_SELECTOR(cartProduct.id))
  const { updateQuantity } = useUpdateCartProducts()
  const { remove } = useRemoveProductOfCart()
  const { max, min, unitInterval } = convertProductUnitKgInGr(product)

  const handleUpdateQuantity = (value: number) => {
    updateQuantity(cartProduct, value)

    if (value === 0) {
      handleRemoveProductOfCart()
    }
  }

  const handleRemoveProductOfCart = () => {
    remove(cartProduct)
  }

  return (
    <>
      <PrimaryButton
        sx={{ mr: 1 }}
        onClick={handleRemoveProductOfCart}
      >
        <DeleteIcon/>
      </PrimaryButton>
      <QuantitySelector
        quantity={cartProduct.quantity}
        onUpdate={handleUpdateQuantity}
        unitInterval={unitInterval}
        unit={product?.unit}
        max={max}
        min={min}
      />
    </>
  )
}

export default CartItemActions
