import { Paper } from '@mui/material'
import CartItems from '~/src/components/cart/CartItems'
import GoToMenuButton from '~/src/components/navigation/GoToMenuButton'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const CartContainer = () => {
  const muiTheme = useCustomMuiTheme()

  return (
    <Paper
      sx={{
        p: 2,
        backgroundColor: 'container.main',
        color: 'container.contrastText'
      }}
      elevation={muiTheme.elevation}
    >
      <GoToMenuButton sx={{ mb: 2 }} />
      <CartItems />
    </Paper>
  )
}

export default CartContainer
