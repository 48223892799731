import { SxProps } from '@mui/material'
import StylesCartServiceInterface from '~/src/pages/cart/styles/StylesCartServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesCartServiceStyleOne extends TemplateServiceStyleBase implements StylesCartServiceInterface {
  public getPageSx(): SxProps {
    return {
      display: 'grid',
      py: 3,
      gridTemplateColumns: 'auto 66% auto',
      gridTemplateRows: 'auto',
      gridTemplateAreas: '". center ."',
      [this.muiTheme.breakpoints.only('xs')]: {
        gridTemplateColumns: 'auto 90% auto'
      }
    }
  }

  public getInnerBoxSx(): SxProps {
    return { gridArea: 'center' }
  }
}
