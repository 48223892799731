import getCartTemplateService from '~/src/pages/cart/styles/GetCartTemplateService'
import StylesCartServiceInterface from '~/src/pages/cart/styles/StylesCartServiceInterface'
import useBackButton from '~/src/hooks/layout/useBackButton'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import routes from '~/src/router/enums/routes.enum'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetCartTemplate = (): StylesCartServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('cart')

  useLayout('cart')
  useBackButton({ href: routes.CATEGORIES, resolve: true })

  return getCartTemplateService(pageStyle, muiTheme)
}

export default useGetCartTemplate
