import StylesCartServiceInterface from '~/src/pages/cart/styles/StylesCartServiceInterface'
import StylesCartServiceStyleOne from '~/src/pages/cart/styles/StylesCartServiceStyleOne'
import StylesCartServiceStyleTwo from '~/src/pages/cart/styles/StylesCartServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getCartTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesCartServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesCartServiceStyleTwo(muiTheme)
    default:
      return new StylesCartServiceStyleOne(muiTheme)
  }
}

export default getCartTemplateService
