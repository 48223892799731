import Link from '~/src/components/mui-wrappers/Link'
import Title from '~/src/components/mui-wrappers/Title'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import routes from '~/src/router/enums/routes.enum'
import { CartProduct } from '~/src/types/CartProduct'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { PRODUCT_BY_ID_SELECTOR } from '~/src/stores/product'

interface Props {
  cartProduct: CartProduct,
}

const CartItemName = ({ cartProduct }: Props) => {
  const muiTheme = useCustomMuiTheme()
  const product = useEoValue(PRODUCT_BY_ID_SELECTOR(cartProduct.id))
  const lang = useEoValue(LANGUAGE_STATE)

  if (!product) {
    return null
  }

  return (
    <Link
      removeBaseLinkStyle
      href={`${routes.CATEGORY}/${product.categoryId}${routes.PRODUCT}/${cartProduct.id}`}
    >
      <Title
        variant='body1'
        sx={{
          fontSize: '1.5em',
          wordWrap: 'break-word',
          wordBreak: 'break-all',
          maxWidth: '100%',
          transition: 'var(--ease-in-out)',
          '&:hover': {
            opacity: 0.5
          },
          [muiTheme.breakpoints.only('xs')]: {
            fontSize: '1.3em'
          }
        }}
      >
        { product.name[lang] }
      </Title>
    </Link>
  )
}

export default CartItemName
